import { useEffect } from "react";
import Aos from "aos";
import { ToastContainer } from "react-toastify";
import { init } from "../utils/sentry";

init();

import Router from "next/router";
import NProgress from "nprogress";
import "nprogress/nprogress.css";

Router.events.on("routeChangeStart", () => NProgress.start());
Router.events.on("routeChangeComplete", () => NProgress.done());
Router.events.on("routeChangeError", () => NProgress.done());

import { Provider } from "next-auth/client";
import { ChartProvider } from "providers/chart";
import "react-toastify/dist/ReactToastify.css";

import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

import "styles/bootstrap.min.css";

import "aos/dist/aos.css";

import "styles/global.css";

import "styles/responsive.css";

import "styles/login.css";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { ptBR } from "@material-ui/data-grid";

const theme = createMuiTheme(
  {
    palette: {
      primary: { main: "#008bd1" },
      success: { main: "#0fff1b" },
    },
  },
  ptBR
);

function MyApp({ Component, pageProps, err }) {
  useEffect(() => {
    Aos.init();
  }, []);

  useEffect(() => {
    if (window) {
      const path = window.location.hash;
      if (path && path.includes("#")) {
        setTimeout(() => {
          const id = path.replace("#", "");
          const el = window.document.getElementById(id);
          const r = el.getBoundingClientRect();
          window.top.scroll({
            top: pageYOffset + r.top,
            behavior: "smooth",
          });
        }, 600);
      }
    }
  }, []);

  return (
    <div className="basePage">
      <ThemeProvider theme={theme}>
        <Provider session={pageProps.session}>
          <ChartProvider>
            <Component {...pageProps} err={err} />
          </ChartProvider>
        </Provider>
      </ThemeProvider>

      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}

export default MyApp;
